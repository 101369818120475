export const patternEmail="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\\.[A-Za-z]{2,20}$";
export const patternNumber="^[0-9]*$";
export const patternHoursMinutes="^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$";
export const patternQuantity= "^[1-9][0-9]*$";
export const patternGenericValuta="^[0-9.]*$";
export const patternPercent="^([0-9]|[1-9][0-9]|[1][0][0]|)$"
export const patternCodiceFiscale="^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$"
//export const patternCodiceFiscale="^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\\\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\\\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\\\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\\\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$"
export const patternLetters="^[a-zA-Z\\\'\\\ò\\\à\\\ù\\\ì\\\è\\\é\\\s]+$";
export const patternCap="^(V-|I-)?[0-9]{5}$";
export const patternIban='^(?:(?:IT|SM)\\\d{2}[A-Z]\\\d{22}|CY\\\d{2}[A-Z]\\\d{23}|NL\\\d{2}[A-Z]{4}\\\d{10}|LV\\\d{2}[A-Z]{4}\\\d{13}|(?:BG|BH|GB|IE)\\\d{2}[A-Z]{4}\\\d{14}|GI\\\d{2}[A-Z]{4}\\\d{15}|RO\\\d{2}[A-Z]{4}\\\d{16}|KW\\\d{2}[A-Z]{4}\\\d{22}|MT\\\d{2}[A-Z]{4}\\\d{23}|NO\\\d{13}|(?:DK|FI|GL|FO)\\\d{16}|MK\\\d{17}|(?:AT|EE|KZ|LU|XK)\\\d{18}|(?:BA|HR|LI|CH|CR)\\\d{19}|(?:GE|DE|LT|ME|RS)\\\d{20}|IL\\\d{21}|(?:AD|CZ|ES|MD|SA)\\\d{22}|PT\\\d{23}|(?:BE|IS)\\\d{24}|(?:FR|MR|MC)\\\d{25}|(?:AL|DO|LB|PL)\\\d{26}|(?:AZ|HU)\\\d{27}|(?:GR|MU)\\\d{28})$';
export const patternFloatNumber="^(\\\d{1,3})(,\\\d{1,3})*(\\\.\\\d{1,})?$";
export const patternUsername="^[a-zA-Z0-9._\\\-@!?]{2,16}$";
export const patternPassword="^[a-zA-Z0-9]{8,16}$";
export const patternPhone = "^[\\\+\\\d]?(?:[\\\d-.\\\s()]*)$";
export const patternLuogoNascita = "^[a-zA-Z\\\s\\\']+$";
export const patternNumeriLettere = "^[a-zA-Z0-9]{0,20}$";
export const patternTestoLibero = "^[a-zA-Z0-9\\\'\\\s\\\.\\\,\\\-\\\à\\\è\\\é\\\ì\\\ò\\\ù\\\\\\\/]{1,1000}$";
export const patternTestoNote = "^[a-zA-Z0-9\\\:\\\!\\\&\\\@\\\#\\\+\\\°\\\*\\\(\\\)\\\;\\\<\\\>\\\%\\\'\\\s\\\.\\\,\\\-\\\à\\\è\\\é\\\ì\\\ò\\\ù\\\\\\\/]{1,1000}$";
export const patternRagioneSociale="^[a-zA-Z0-9\\\:\\\!\\\&\\\$\\\@\\\#\\\+\\\°\\\*\\\|\\\^\\\'\\\s\\\.\\\,\\\-\\\à\\\è\\\é\\\ì\\\ò\\\ù\\\\\\\/]{1,1000}$";
export const patternProvincia = "^[a-zA-Z]{2}$";
export const patternTargaBus="[A-Za-z]{2}[0-9]{3}[A-Za-z]{2}";
export const patternDateFormatIT='^(?:(?:31(\\\/|-|\\\.)(?:0?[13578]|1[02]))\\\\1|(?:(?:29|30)(\\\/|-|\\\.)(?:0?[13-9]|1[0-2])\\\\2))(?:(?:1[6-9]|[2-9]\\\d)?\\\d{2})$|^(?:29(\\\/|-|\\\.)0?2\\\\3(?:(?:(?:1[6-9]|[2-9]\\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\\d|2[0-8])(\\\/|-|\\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\\\4(?:(?:1[6-9]|[2-9]\\\d)?\\\d{2})$';
export const patternTwoDecimals="^\\\d{0,10}(\\\.\\\d{1,2})?$"
